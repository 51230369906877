import React from 'react';
import { noop } from 'src/types/basic';

export interface PageMetaValues {
  title: string;
  description: string;
  backLabel: string;
}
export interface PageMetaState {
  title: string;
  description: string;
  backLabel: string;
  previousPath: string;
  setPageMeta: (meta: Partial<PageMetaValues>) => void;
  resetPageMeta: () => void;
}

export const initialPageMetaData: PageMetaValues = {
  title: '',
  description: '',
  backLabel: '',
};

const initialState: PageMetaState = {
  ...initialPageMetaData,
  previousPath: '',
  setPageMeta: noop,
  resetPageMeta: noop,
};

export const PageMetaContext = React.createContext<PageMetaState>(initialState);
